import smartphone from '../img/ovie_wale.gif'
import React from 'react'

function Unforgettable() {

    const data = [
        {
            header: 'Demand for animated content',
            text: 'The booming popularity of animation in movies, TV shows, and digital media platforms creates a strong demand for high-quality animated content, allowing studios and creators to showcase their talent and capture market share'
        },
        {
            header: 'Brand storytelling and advertising',
            text: 'Animation offers a versatile and engaging medium for brand storytelling and advertising, enabling companies to convey complex concepts, captivate audiences, and leave a lasting impact across various marketing channels.',
        },
        {
            header: 'E-learning and educational content',
            text: 'Animation\'s ability to make subjects engaging and understandable makes it ideal for e-learning and educational content, presenting animation studios and creators with the chance to contribute to the growing demand for online education and digital learning platforms.',
        },
    ]
    return (
        <div className="text-left container xl:px-40 md:px-16 px-4 py-8 lg:gap-24 gap-4
        lg:py-20 grid lg:grid-cols-2 space-x-4 space-y-12 place-items-start lg:place-items-center">
              <div className='grid justify-center w-full bg-orange-300 rounded-lg'>
                <img src={smartphone} alt="ovie and wale have contributed a lot to the growth of the animation industry.
                 Check our website at https://ovieandwale.com" />
            </div>
            <div className="grid space-y-6 w-full">
                <h1 className="text-2xl lg:text-4xl lg:leading-normal font-bold">From Invisible to Exceptional With Orange VFX Studios</h1>
                {data.map((datum) => (
                    <div className="text grid gap-4">
                        <h6 className="text-lg lg:text-xl leading-loose">{datum.header}</h6>
                        <p className="leading-loose text-md">{datum.text}</p>
                    </div>
                ))}


            </div>
          
        </div>


    );
}

export default Unforgettable;




import React from 'react'
import Facebook from '../img/socials/facebook.png'
import Instagram from '../img/socials/instagram.svg'
import Twitter from '../img/socials/twitter.png'
import Linkedin from '../img/socials/linkedin.svg'

const Footer = () => {
    const data = [
        {
            header: 'Company',
            links: [{ text: 'Why Orange VFX?', link: "#" },
            { text: 'About', link: "https://orangevfx.com/us/" },
            { text: 'Policy and Privacy', link: "https://orangevfx.com/us/policy/" },
            { text: 'Careers', link: "https://orangevfx.com/careers/" },
            { text: 'Investors', link: "https://investor.orangevfx.com" },
            { text: 'Ovie and Wale', link: "https://ovieandwale.com/" },
            { text: 'gods and deities', link: "https://orangevfx.com/africa-gods-and-deities/" }]
        },

        {
            header: 'Services',
            links: [{ text: 'Our Portfolio', link: "https://orangevfx.com/our-work/" },
            { text: '3D Animation', link: "https://orangevfx.com/services/3d-animation/" },
            { text: 'Visual Effects', link: "https://orangevfx.com/services/visual-effects/" },
            { text: 'Motion Design', link: "https://orangevfx.com/services/motion-graphics/" },
            { text: 'Product Visualization', link: "https://orangevfx.com/services/product/" },
            { text: 'Character Design', link: "https://orangevfx.com/services/character-design/" },
            { text: 'Game', link: "https://orangevfx.com/services/game-ar-vr/" }]
        },


        {
            header: 'Training',
            links: [{ text: 'Learn By Job Roles', link: "https://orangevfx.com/training/roles" },
            { text: 'Courses', link: "https://orangevfx.com/training/" },
            { text: 'Masterclass', link: "https://orangevfx.com/training/masterclass/" },
            { text: 'Summer camp', link: "https://orangevfx.com/maincamp/" },
            { text: 'Free Webinar', link: "https://orangevfx.com/us/free-seminar/" },
            { text: 'Student Hub', link: "https://orangevfx.com/orange-seed-initiative/" },
            { text: 'Seed Training Program', link: "https://orangevfx.com/orange-seed-initiative/" }]
        }


    ]

    return (
        <>
            <div class="bg-orange-50 text-left">
                <div class="grid grid-cols-1 md:grid-cols-2 space-y-10 md:space-y-0 gap-10 lg:grid-cols-4 md:px-16 xl:px-40 px-4 py-20">
                    <div class="contact space-y-4 leading-loose">
                        <h6 class="text-xl">Contact Us</h6>
                        <p>investor@orangevfx.com</p>
                        <p>+234-814-771-9409</p>
                        <p>1A, Raufu Williams
                            Crescent, <br />Surulere,
                            Lagos, Nigeria</p>
                        <div class="flex flex-row space-x-4 pt-4">
                            <a href='https://www.facebook.com/OrangeVFX/' target='_blank'><img src={Facebook} alt="" class="w-8" /></a>
                            <a href='https://www.instagram.com/orangevfxstudios/' target='_blank'><img src={Instagram} alt="" class="w-8" /></a>
                            <a href='https://ng.linkedin.com/company/orangevfx' target='_blank'><img src={Linkedin} alt="" class="w-8" /></a>
                            <a href='https://twitter.com/OrangeVFX' target='_blank'><img src={Twitter} alt="" class="w-8" /></a>
                        </div>
                    </div>

                    {data.map((datum) => (
                        <div class="space-y-4 leading-loose">
                            <h6 class="text-xl">{datum.header}</h6>
                            <ul class="leading-loose space-y-2">
                                {datum.links.map((list) => (
                                    <li className='hover:text-orange-500'><a href={list.link}>{list.text}</a></li>
                                ))}
                            </ul>
                        </div>
                    ))}

                </div>

                <div class="text-center py-8 border-t-[0.5px] border-zinc-400 text-sm lg:mx-16 xl:mx-40 mx-4 leading-relaxed">
                    <p>© 2023 Orange Visual Effects Limited. All Rights Reserved.</p>
                </div>
            </div>



        </>
    )
}

export default Footer
// src/Modal.js
import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <div className={`fixed inset-0 overflow-y-auto ${isOpen ? '' : 'hidden'} overflow-y-hidden z-50 bg-zinc-500/90`} onClick={handleClose }>
      <div className="flex items-center min-h-screen rounded-lg">
        <div className="p-8 w-screen xl:mx-60 mx-2">
            <div className='flex justify-center rounded-lg'>{children}</div>

        </div>
      </div>
    </div>
  );
};

export default Modal;
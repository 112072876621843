import './App.css';
import Unforgettable from './components/Unforgettable'
import UseCases from './components/UseCases'
import Newsletter from './components/Newsletter'
import Solutions from './components/Solutions'
import Opportunities from './components/Opportunities'
import Faqs from './components/Faqs'
import React from 'react'
import Footer from './components/Footer'
import Message from './components/Message'
import Revenue from './components/Revenue'
import Navbar from './components/Navbar'
import Client from './components/Client';
import Hero from './components/Hero';

function App() {
  return (
    <div className="App text-left text-[#212121]">
      <Navbar />
      <Hero />
     
      <Unforgettable />
      <UseCases />
      <Opportunities />
      <Client />
      <Solutions />
      <Revenue />
      <Faqs />
      <Message />
      <Footer />
    </div>
  );
}

export default App;

import video from '../img/video_reel.png'
import play from '../img/texticon/play.png'
import React from 'react'
import Modal from './Modal';
import { useState, useEffect } from 'react'

function Opportunities() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [isModalOpen]);

    const data = [
        'The African animation industry presents vast opportunities for growth, \
        driven by a large population and increasing demand for quality animated content. \
        With diverse cultures and storytelling traditions, Africa\'s animation sector \
        can create unique and culturally relevant stories.',


        'According to imarcgroup, \
        the Africa animation market size reached  US$ 12.3 Billion in 2022.\
        Nigeria Advertising spend in 2020 was about $480M. We are aiming for at least 0.1% \
        The rise of digital platforms expands distribution channels, and investments are being made \
        in talent development and infrastructure. The industry has the potential to generate jobs, \
        foster economic development, and deliver captivating animated content to both African \
        and global audiences.'
    ]
    return (
        <>
            <div className={`container xl:px-40 lg:pr-2 md:pl-16 px-4 py-20 grid lg:grid-cols-2 grid-cols-1 space-x-4 gap-12 
            place-items-start lg:place-items-center gap-8 ${isModalOpen ? 'opacity-10' : ''}`}>
                <div class="place-items-start xl:place-items-center relative w-full z-0">
                    <img src={video} alt="" class="w-full h-auto" />
                    <div class="absolute top-0 bottom-0 left-0 right-0">
                        <img src={play} class="lg:w-[80px] w-[40px] md:w-[60px] absolute top-[40%] right-[43%] pulse rounded-full" onClick={openModal}
                         alt='This is used to play our youtube video, which can be accessed on https://youtube.com/orangevfx'/>
                    </div>
                </div>


                <div className="text text-left grid space-y-2 gap-4">
                    <h1 className="text-2xl lg:text-4xl lg:leading-normal leading-relaxed font-semibold">At 
                     <span className='text-orange-500'> Orange VFX, </span>We Seize Opportunities For Creativity</h1>
                    <div className="text mt-4 space-y-4">
                        {data.map((datum) => (
                            <p className="leading-loose">{datum}</p>
                        ))}

                    </div>

                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal} 
            onClick={isModalOpen ==  true ? closeModal : ''} className="bg-orange-500 w-full h-screen">
                {isModalOpen ? <iframe width="100%" className="rounded-lg h-96 md:h-[80vh]" src="https://www.youtube.com/embed/MZbsUR0Yg8k"
                    title="Orange VFX Reel 2022" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
                    encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> : ''}
                
            </Modal>
        </>
    )
}


export default Opportunities
import React from 'react'
import training from '../img/texticon/presentation.png'
import service from '../img/texticon/services.png'
import content from '../img/texticon/content.png'

const Revenue = () => {
    return (
        <div id="revenue" className="cards bg-[#212121] leading-loose py-20 grid place-items-center">
            <div className="text-center text-white space-y-8">
                <h1 className="xl:text-6xl text-4xl xl:leading-snug font-semibold leading-relaxed">We Take Pride <br />
                    In Creating Value <br /> 
                    For <span className="text-orange-500 font-[Brygada-1918]"><i>African Brands</i></span>
                </h1>
                <div className="xl:px-96 px-10">
                    <p className="lg:text-lg lg:leading-loose text-md">Orange Visual Effects Limited 
                        has over 13 years creating
                        digital content for both multinational organizations and local
                        brands. Our profitability is tied to our value, which we aim to
                        improve through CSRs and human empowerment.
                    </p>
                </div>

            </div>



            <div className="text-left cards grid lg:grid-cols-3 grid-cols-1 xl:px-40 md:px-16 px-4 lg:py-20 py-12">
            <div className="card bg-zinc-800 text-white rounded-lg space-y-4 p-10 lg:hover:-translate-y-6 shadow-2xl">
                <img src={service} alt='' className='w-16'/>
                <h6 className="text-white text-lg">Services</h6>
                <p>We produce content for brands that help
                    them generate leads, drive engagement and make sales</p>
            </div>

            <div className="card text-white bg-zinc-800 rounded-lg p-10 space-y-4
            lg:hover:-translate-y-6 lg:-ml-16 ml-0 shadow-2xl mt-12 lg:mt-0">
                <img src={training} alt='' className='w-16'/>
                <h6 className="text-white text-lg">Training</h6>
                <p>Orange Seed Program trains, inspires and
                    mentors animators as well as foster talent developments</p>
            </div>

            <div className="card text-white bg-zinc-800 rounded-lg p-10 space-y-4 
            lg:hover:-translate-y-6 lg:-ml-16 ml-0 shadow-2xl mt-12 lg:mt-0">
                <img src={content} alt='' className='w-16'/>
                <h6 className="text-white text-lg">Content</h6>
                <p>We produce our own content using our IPs and
                    licensed characters like Wale, Ovie and Chi-chi.</p>
            </div>
        </div>
        </div>
    )
}

export default Revenue
import React from "react"
import { useState } from "react";

function Newsletter() {

  const {message, setMessage} = useState('')

  const [postData, setPostData] = useState('');

  const handleInputChange = (event) => {
    setPostData(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://api.clickup.com/api/v2/list/901500506543/task?custom_task_ids=true&team_id=9015089332', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'pk_72019973_VNUZTE9IHIJ2OZ6L6TGUKG35V5UKFY4H'
        },
        body: JSON.stringify({
          "name": postData,
          "description": "New Task Description",
          "assignees": [],
          "tags": [],
          "status": "to do",
          "priority": 3,
          "due_date": 1508369194377,
          "due_date_time": false,
          "time_estimate": 8640000,
          "start_date": 1567780450202,
          "start_date_time": false,
          "notify_all": true,
          "parent": null,
          "links_to": null
          
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setMessage('<p>You have successfully Registered to our mailing list<p>')
      return responseData
  
    } catch (error) {
      console.error('Error:', error);
    }
  };

 


    return (
        <div className="px-4 md:px-28 xl:px-96 py-20 bg-[#212121] grid place-items-center form-text space-y-8">
            <div className="text space-y-8">
                <h1 className="text-3xl xl:text-5xl text-center leading-relaxed text-white font-semibold">
                  Want to see our <span class="text-orange-500 font-[Brygada-1918]"><i>pitch deck?</i></span>
                </h1>
                <p className="xl:text-lg xl:leading-loose leading-loose text-center text-white">Sign up to our email newsletter to get access to our
                    pitch deck and other relevant information as it arises. We Promise not to spam your mailbox with unwarranted messages.
                    Read our privacy policy here.</p>
            </div>
            <form action="" method="post" class="space-x-4 w-full mx-12 flex" onSubmit={handleSubmit}>
                <input type="email" required="" id="" placeholder="Email Address" className=" w-full flex-1 border-0 bg-white py-4 pl-4 text-gray-900 
                     focus:ring-0 text-sm sm:leading-6" onChange={handleInputChange} value={postData.email} />
                <button type="submit" class="bg-orange-600 px-6 py-4 text-sm text-white hover:bg-transparent">Submit</button>
            </form>
            {message}
        </div>
    )
}

export default Newsletter
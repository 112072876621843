import React from 'react'
import question from '../img/texticon/question.png'


function Faqs() {

  const data = [
    {
      question: 'What is the business model of the company?',
      answer: 'As a company,, we produce content for other brands to help with their marketing, we train aspiring digital artists and produce our own content for African storytelling and promoting the African culture.'
    },
    {
      question: 'What is our potential for growth?',
      answer: 'The Africa animation market size reached US$ 12.3 Billion in 2022 and the advertising spend for Nigeria in 2020 is , with the right strategies in place, we can reach a sizeable percentage of this market.'
    },
    {
      question: 'What milestones have been achieved?',
      answer: 'We are in the development stage of our business. Having developed our IPS, and worked with a good number of clients over the years. We have also built our school that has trained hundreds of professionals and provided jobs for some of them too.'
    },
    {
      question: 'How do we generate revenue?',
      answer: 'There are currently 3 cash cows in our company; We produce content for other brands, we have a school that trains animators and we also produce our own content.'
    },
    {
      question: 'What is the management background?',
      answer: 'Our Management Team comprises professionals with years of experience in animation and business management.'
    }
  ]
  return (
    <div id="faqs" class="text-left space-y-4 md:space-y-10 py-20 xl:px-40 md:px-16 px-4">
      <div class="grid lg:grid-cols-2 lg:grid-cols-1">
        <div class="space-y-4 leading-loose">
          <h1 class="xl:text-4xl text-2xl leading-normal font-semibold">Any Questions? Check Here!</h1>
          <p class="xl:text-lg text-md xl:leading-loose">Here are a few questions asked by other potential investors.
            We are ready to answer your question at any time of the day.
            Shoot us a mail at investor@orangevfx.com
          </p>
        </div>
        <div></div>

      </div>



      <div class="grid text-left lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 space-y-10 md:space-y-0 space-x-0 leading-loose">
        {data.map((datum) => (
          <div class="space-y-4">
            <img src={question} alt="frequently asked questions on orange vfx" />
            <h6 class="text-lg">{datum.question}</h6>
            <p>{datum.answer}</p>
          </div>
        ))}
      </div>







    </div>
  )
}

export default Faqs
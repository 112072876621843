import React from 'react'
import storytelling from '../img/cases/story.png'
import game from '../img/cases/game-controller.png'
import tv from '../img/cases/television.png'
import architect from '../img/cases/architecture.png'
import chat from '../img/cases/communication.png'
import ad from '../img/cases/ad.png'
import imageBg from '../img/bg-head.png'

function UseCases() {
    const bgStyle = {
        backgroundImage: `url(${imageBg})`
    }
    const data = [
        {
            field: 'Marketing',
            text: 'Animation breaks barriers, bringing imagination to life, enhancing brand differentiation and customer understanding.',
            imgUrl: ad
        },
        {
            field: 'Storytelling',
            text: 'Animation in storytelling unleashes limitless creativity, captivating audiences with imaginative worlds and characters.',
            imgUrl: storytelling
        },
        {
            field: 'Games',
            text: 'Animation breaks barriers, bringing imagination to life, enhancing brand differentiation and customer understanding.',
            imgUrl: game
        },
        {
            field: 'TV & Film',
            text: 'Animation in TV and films transcends the boundaries of reality, captivating audiences with its imaginative power and depth',
            imgUrl: tv
        },
        {
            field: 'Architecture',
            text: 'Animation in architecture revolutionizes the design process by visually communicating complex concepts.',
            imgUrl: architect
        }
        ,
        {
            field: 'Communication',
            text: 'From presentations to storytelling, animation engages and clarifies messages for a compelling and memorable experience',
            imgUrl: chat
        }
    ]
    return (
        <div class="text-left bg-orange-50 px-4 xl:px-40 md:px-16 py-20 grid gap-8 bg-cover bg-orange-50">
            <div class="header grid grid-cols-1 md:grid-cols-2">
                <div className='space-y-4'>
                    <h1 class="xl:text-5xl text-3xl leading-loose xl:leading-loose font-semibold">Use Cases</h1>
                    <p class="xl:text-lg text-md leading-loose xl:leading-loose">Transform ideas into vibrant reality with Orange VFX. Elevate games, architecture, communication, marketing, film,
                        and TV through stunning animations</p>
                </div>
                <div></div>

            </div>
            <div class="grid grid-cols-1 md:gap-6 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {data.map((datum)=>(
                    <div className="icon-box shadow-2xl bg-white p-8 grid gap-4 rounded-lg">
                    <div className="img">
                        <img src={datum.imgUrl} alt="" width="80px" />
                    </div>
                    <div className="text grid gap-4">
                        <h6 className="lg:text-xl text-lg leading-loose">{datum.field}</h6>
                        <p className="leading-loose">{datum.text}</p>
                    </div>
                        
                </div>
                ))}

            </div>
        </div>
    )
}

export default UseCases
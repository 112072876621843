import tick from '../img/texticon/tick.svg'
import invest from '../img/texticon/rocket.gif'
import React from 'react'

function Solutions () {
    const data = [
        'We provide tailored solutions to help businesses create impactful animated content aligned with their brand and objectives',
        'We use creative approaches that make brands stand out and connect emotionally with their audiences.',
        'We use cutting-edge techniques and tools for visually stunning and immersive experiences.',
        'We engage in partnerships to undertake ambitious projects, expand reach, and access new markets',
        'We carry out initiatives to empower aspiring animators and foster talent development.',
        'Integration of animation into various sectors for enhanced communication and visualization.'
    ]
    return (
        <div class="text-left container xl:px-40 md:px-16 px-4 py-20 grid lg:grid-cols-2 
        space-x-4 space-y-12 place-items-center gap-x-28">
            <div class="space-y-8">
                <h1 class="text-2xl lg:text-4xl lg:leading-normal leading-relaxed font-semibold">Unlocking Success: How <span className='text-orange-500'>Orange VFX </span>Solve Problems</h1>
                <ul class="space-y-2 list-image[url('img/texticon/tick.png')]">
                    {data.map((datum)=>(
                        <li class="leading-loose flex flex-row place-items-start gap-4"> <img src={tick} alt="
                        solutions to digital content issues at orange vfx" className="lg:w-[40px] w-[30px]" />
                        <span>{datum}</span></li>
                    ))}
                </ul>
            </div>
            
            <div className='flex justify-start w-full h-full bg-green-50'>
                <img src={invest} className='p-4'
                alt="Would you like to invest in Africa's creattive talents, here is a big chance to achieve that." />
            </div>
        </div>
    )
}

export default Solutions
import React from 'react'

const Message = () => {

    return (
        <>
            <div id="contact" className="xl:mx-40 md:mx-16 mx-4 px-4 mb-20 bg-[#212121] py-20 space-y-10 shadow-2xl rounded-3xl grid place-items-center">
                <h1 className="text-white text-center xl:text-5xl font-semibold text-3xl xl:leading-normal leading-normal">
                    <span class="text-orange-500 font-[Brygada 1918]"><i>Want to Invest?</i></span><br />
                    Send Us A Message</h1>
                <div className='xl:px-48 px-4'>
                <p className="text-white xl:text-lg text-center xl:leading-loose leading-loose ">Our Business Development team will respond
                    to you promptly. <br className='lg:block hidden' /> Email us at <a href='mailto:investor@orangevfx.com' className='text-orange-500 hover:text-white'><i>investor@orangevfx.com.</i></a>
                </p>
                </div>
                
    
            </div>

       
        </>

    )
}

export default Message
import React from 'react'
import iom from '../img/logos/IOM.png'
import au from '../img/logos/au.png'
import hollandia from '../img/logos/Hollandia.png'
import axxela from '../img/logos/Axxela.png'
import mtn from '../img/logos/MTN.png'
import firstbank from '../img/logos/firstbank.png'
import indomie from '../img/logos/indomie.png'
import airtel from '../img/logos/airtel.png'
import unicef from '../img/logos/UNICEF.png'
import chivita from '../img/logos/chivita.png'
import wakanow from '../img/logos/wakanow.png'
import dstv from '../img/logos/Dstv.png'


const Client = () => {


    const data = [
        iom, airtel, hollandia, chivita, mtn, firstbank, axxela, indomie,
        au, unicef, wakanow, dstv]

    return (
        <div className="py-20 xl:px-40 px-4 md:px-16 flex flex-col space-y-12 py-30 text-left bg-orange-50">
            <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div className="text space-y-8">
                <h1 className="xl:text-5xl text-3xl xl:leading-normal font-semibold leading-relaxed">We Have Served
                    <br /> <span class="text-orange-500">50+ Businesses</span></h1>
                <p class="xl:text-lg xl:leading-loose leading-loose">Orange Visual Effect Limited Has provided top-notch services to
                    various clients for over 13 years.</p>
            </div>
            </div>

            <div class="flex flex-row flex-wrap gap-y-16 lg:gap-x-16 gap-x-6 place-items-start">
                {data.map((datum) => (
                    <img src={datum} alt="" class="xl:w-28 w-20 p-2" />
                ))}


            </div>
        </div>
    )
}

export default Client

import invest from '../img/africa bg.png'

import React from 'react'
import Typed from 'typed.js';



const Hero = () => {

    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['<span>We Create <span class="text-orange-500 font-[Brygada-1918]">Epic Contents</span></span>', 
            '<span>We Are <span class="text-orange-500 font-[Brygada-1918]">Pan African</span></span>', 
            '<span>We Empower <span class="text-orange-500 font-[Brygada-1918]">People</span></span>'],
            loop: true,
            typeSpeed: 80,
            backSpeed: 80
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);

    return (
        <div className={`xl:px-40 md:px-16 px-4 lg:pt-48 pt-32 pb-40 h-full bg-cover text-left hero`} style={{backgroundImage: 
        "linear-gradient(90deg, #ef745c, #34073d)"}}>
            <div className="grid justify-self-end space-y-12 align-middle place-items-center gap-16 w-full">
                <div className="slide_1_txt grid space-y-8">
                    <h1 className="xl:text-7xl lg:text-4xl text-3xl font-semibold leading-relaxed text-center text-white pt-20 lg:pt-0">
                        <span ref={el}></span>
                    </h1>

                    <p className="leading-loose text-center text-white">We help brands unleash their potentials with our cutting-edge animation and
                        visual effects services, crafted exclusively for African businesses. We also empower
                        the next generation of African Digital Content
                        Creators, creating captivating digital content that
                        transforms Africa's narrative through the power of CreaTech.</p>
                    <div className='flex flex-row gap-4 justify-center font-[Onest]'>
                        <a href="https://orangevfx.com" target='_blank' className='bg-orange-500 text-white border-solid hover:border-[1px] p-4 rounded-md hover:bg-transparent rounded-md text-sm'>Get Started</a>
                        <a href='mailto:investor@orangevfx.com' target="_blank" className='hover:bg-orange-500 text-white border-white hover:border-transparent border-solid border-[1px] p-4 rounded-md text-sm'>Contact Us</a>
                    </div>

                </div>
                

            </div>
        </div>
    );
}



export default Hero

import React from 'react'
import Logo from '../img/Orange Logo.png'
import Hamburger from '../img/texticon/hamburger.png'
import close from '../img/texticon/close.png'
import { useState, useEffect } from 'react'


const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);



    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };





    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden');
            document.body.classList.add('lg:overflow-y-visible')
        } else {
            document.body.classList.remove('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
            document.body.classList.remove('lg:overflow-y-visible');
        };
    }, [isOpen]);


    useEffect(() => {
        const handleScroll = () => {

            const scrolled = window.scrollY > 100;
            setIsScrolled(scrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isScrolled]);

    const links = [
        {
            text: 'About',
            link: 'https://orangevfx.com/us',
            target: '_blank'
        },
        {
            text: 'Services',
            link: 'https://orangevfx.com/services',
            target: '_blank'
        },
        {
            text: 'Revenue',
            link: '#revenue',
        },
        {
            text: 'Our Team',
            link: 'https://orangevfx.com/us/our-team',
            target: '_blank'
        },
        {
            text: 'FAQs',
            link: '#faqs',
        }
    ]

    return (
        <>
            <nav class={`flex lg:flex-row flex-col lg:px-16 xl:px-40 place-items-center
        lg:py-0 w-[100%] md:h-[100px] h-[70px]' fixed top-0 z-10 
        ${isScrolled ? 'lg:bg-white bg-transparent shadow-lg border-b-[2px]' : 'bg-transparent'}`}>
                <div class="text-white grid grid-cols-2 lg:place-items-start py-4 w-full bg-white lg:bg-transparent px-4 shadow-lg lg:shadow-none">
                    <img src={Logo} alt="" class="place-items-start lg:w-[60px] w-[40px]" />
                    <img src={isOpen ? close : Hamburger} alt="" className="w-[30px] lg:w-[40px] lg:hidden place-self-end self-center"
                        onClick={toggleMenu} />
                </div>
                <div className={`w-screen font-[Onest] lg:block 
                    lg:text-right lg:text-center text-left lg:bg-transparent`}>
                    <div class={`text-md lg:space-x-6 space-y-4 py-8 self-begin lg:self-center text-gray-800 ${isOpen ? 'block h-screen' : 'hidden lg:block'} 
                    lg:pb-12 bg-orange-300 lg:bg-transparent w-[80%] pl-4 lg:w-full h-screen lg:h-full`}>
                        {links.map((data) => (
                            <a href={data.link} target={data.target}
                                className={`block mt-4 lg:mt-0 lg:inline-block lg:mt-0 text-2xl lg:text-base font-thin
                                ${isScrolled ? '' : 'lg:text-white text-zinc-800'} 
                            font-semibold`}>
                                {data.text}
                            </a>
                        ))}
                        <a href='https://orangevfx.com/seed' className={`block mt-4 text-gray-800 lg:text-white text-2xl lg:text-base font-thin 
                        lg:mt-0 lg:inline-block lg:mt-0 lg:bg-[#ff7f00] rounded-lg px-0 lg:px-4 py-0 lg:py-2 
                            font-semibold`}>Orange Seed</a>



                    </div>

                </div>


            </nav>

        </>
    )
}

export default Navbar